import { useState } from 'react';
import { useFetchOrders } from '../../../../api/automator/orders/useFetchOrders';
import { OrderStatus } from '../../../../domain/automator/orders/OrderStatus';
import { DistributionParty } from '../../../../domain/automator/orders/DistributionParty';
import { WarningAlert } from '../../../../components/alerts/WarningAlert';
import { OrderTableRow } from '../components/OrderTableRow';
import { OrdersMode } from '../OrdersPage';

const LOCAL_STORAGE_KEY = 'viewedAlerts';

export const useGenerateFailedOrderWarningAlerts = (distributionParty: DistributionParty) => {
  const { data: failedOrders, isLoading: isLoadingFailedOrders } = useFetchOrders(
    OrderStatus.FAILED,
    distributionParty,
    undefined,
    undefined
  );

  // Local state to track viewed alerts dynamically
  const [localViewedAlerts, setLocalViewedAlerts] = useState<Set<string>>(() => {
    const stored = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '[]');
    return new Set(stored);
  });

  if (isLoadingFailedOrders || !failedOrders || failedOrders.orders.length === 0) {
    return [];
  }

  const handleAlertClose = (id: string) => {
    const updatedViewedAlerts = new Set(localViewedAlerts);
    updatedViewedAlerts.add(id);

    // Update local state and persist to localStorage
    setLocalViewedAlerts(updatedViewedAlerts);
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(Array.from(updatedViewedAlerts)));
  };

  const alerts = failedOrders.orders
    .filter((order) => {
      const id = `${order.bolOrderId}_${order.status}`;
      return !localViewedAlerts.has(id);
    })
    .map((order) => {
      const id = `${order.bolOrderId}_${order.status}`;
      return (
        <WarningAlert
          key={id}
          onClose={() => handleAlertClose(id)}
        >
          <OrderTableRow
            order={order}
            mode={OrdersMode.VIEW}
            isOrderSelected={false}
            distributionParty={distributionParty}
            selectedOrderItemIds={[]}
            onSelectOrderItem={() => {}}
          />
        </WarningAlert>
      );
    });

  return alerts;
};
